/*
| Developed by Starton
| Filename : Loading.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

'use client'

import { Box, CircularProgress } from '@mui/material'
import { Logo } from '@starton/design-system'
import { motion, useAnimationControls } from 'framer-motion'
import { useEffect } from 'react'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type Props = {
	isLoading?: boolean
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const Loading = ({ isLoading = true }: Props) => {
	const controls = useAnimationControls()

	// Effect
	// ----------------------------------------------------------------------------
	useEffect(() => {
		if (!isLoading) {
			controls.start({ opacity: 0 })
		} else {
			controls.start({ opacity: 1 })
		}
	}, [isLoading, controls])

	// Render
	// ----------------------------------------------------------------------------
	return (
		<motion.div
			initial={{ opacity: 1 }}
			animate={controls}
			transition={{ duration: 0.5, ease: 'easeInOut' }}
			style={{
				position: 'fixed',
				top: 0,
				height: '100vh',
				width: '100vw',
				zIndex: 9999,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				pointerEvents: isLoading ? 'auto' : 'none',
			}}
		>
			<Box
				sx={{
					width: '100%',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					gap: 4,
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: (theme) => theme.palette.background.default,
				}}
			>
				<motion.div
					animate={{ scale: [1.0, 0.95, 1.05, 0.95] }}
					transition={{ duration: 2, repeat: Infinity, ease: 'easeInOut' }}
				>
					<Logo sx={{ width: 200, height: 'fit-content' }} />
				</motion.div>
				<CircularProgress size={24} color="primary" />
			</Box>
		</motion.div>
	)
}
