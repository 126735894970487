/*
| Developed by Starton
| Filename : ThemeProvider.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

'use client'

import React from 'react'
import { NuqsAdapter } from 'nuqs/adapters/next/app'
import { ThemeProvider } from '@starton/design-system'
import { Toaster } from 'sonner'
import { AuthProvider } from '@/modules/auth/provider'
import { AppAbility, defineRulesFor } from '@/services/casl/casl-ability'
import { PureAbility } from '@casl/ability'
import { useSessionMe } from '@/modules/auth/api/session.hooks'
import { AbilityProvider } from '@/services/casl/casl-context'
import { Box, CircularProgress } from '@mui/material'
import { Loading } from '@/components/common'
import { SWRConfig } from 'swr'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const AppProvider = ({ children }: { children: React.ReactNode }) => {
	const { data: user, isLoading } = useSessionMe()
	const [ability, setAbility] = React.useState<AppAbility>(new PureAbility())

	// Update ability with user
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		const rules = defineRulesFor(user)
		setAbility(rules)
	}, [user])

	// Render
	// ----------------------------------------------------------------------------
	return (
		<SWRConfig>
			<NuqsAdapter>
				<ThemeProvider>
					<AuthProvider>
						<AbilityProvider ability={ability}>
							<Toaster position="bottom-center" />
							<Loading isLoading={isLoading} />
							{children}
						</AbilityProvider>
					</AuthProvider>
				</ThemeProvider>
			</NuqsAdapter>
		</SWRConfig>
	)
}
