/*
| Developed by Starton
| Filename : casl-ability.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import { AbilityBuilder, ConditionsMatcher, createMongoAbility, MatchConditions, PureAbility } from '@casl/ability'
import { User, UserRole, UserStatus } from '@/modules/user/api/user.dtos'
import { Project } from '@/modules/project/api/project.dto'

/*
|--------------------------------------------------------------------------
| Contract
|--------------------------------------------------------------------------
*/

/**
 * Actions for the ability
 * - manage : full access
 * - read : read only
 * - create : create
 * - update : update
 * - delete : delete
 */
type Actions = 'manage' | 'read' | 'create' | 'update' | 'delete'

/**
 * Subjects for the ability
 * - Project : Project
 * - User : User
 * - all : all
 */
export type Subjects = 'Project' | 'User' | 'all'

/**
 * Type for the ability
 */
export type AppAbility = PureAbility<[Actions, Subjects], MatchConditions>

const lambdaMatcher: ConditionsMatcher<MatchConditions> = (matchConditions) => matchConditions

/*
|--------------------------------------------------------------------------
| Ability
|--------------------------------------------------------------------------
*/
export function defineRulesFor(user?: User): AppAbility {
	const { can, cannot, build } = new AbilityBuilder<AppAbility>(createMongoAbility)

	// If no user is provided (logged out state), deny all access
	// ----------------------------------------------------------------------------
	if (!user) {
		cannot('manage', 'all')
		return build({ conditionsMatcher: lambdaMatcher })
	}

	// Check if the user is active, if not, he cannot manage anything
	// ----------------------------------------------------------------------------
	if (user.status !== UserStatus.ACTIVE) {
		cannot('manage', 'all')
		return build({ conditionsMatcher: lambdaMatcher })
	}

	// If the user is an admin, he can manage everything
	// ----------------------------------------------------------------------------
	if (user.role === UserRole.ADMIN) {
		can('manage', 'all')
	}

	// Check if the user is a user, if not, he cannot manage anything
	// ----------------------------------------------------------------------------
	if (user.role === UserRole.USER) {
		can('create', 'Project')
		can('read', 'Project', ({ userId }: Project) => userId === user.id)
		cannot(['update', 'delete'], 'Project', ({ userId }: Project) => userId === user.id)
	}

	// Build
	// ----------------------------------------------------------------------------
	return build({ conditionsMatcher: lambdaMatcher })
}
